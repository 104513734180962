@import '../../../styles/helpers';

.cases {
  padding: 64px 0;

  @include media(notMobile) {
    padding: 88px 0;
  }

  &__subtitle {
    @include setProperty(font-weight, 300, 500);
    font-size: 14px;
    line-height: 2;
  }

  &__header {
    display: grid;
    gap: 30px;
    margin-bottom: 48px;

    @include media(portrait) {
      margin-bottom: 140px;
    }

    @include media(landscape) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0;
      margin-bottom: 80px;
    }

    @include media(desktopAll) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0;
      margin-bottom: 140px;
    }

    &.alternative-view {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      margin-bottom: 44px;

      .title {
        max-width: 310px;
      }

      .linkButton {
        display: flex;

        @include media(mobile) {
          width: 100%;
        }
      }
    }
  }
}

.linkButton {
  display: none;

  .linkIcon {
    max-width: 24px;
    max-height: 24px;
  }

  @include media(mobile) {
    margin-left: auto;
    margin-top: 48px;
  }
}

.relatedCases {
  padding-top: 64px;
  padding-bottom: 40px;

  @include media(tablet) {
    padding-top: 96px;
  }

  @include media(desktopAll) {
    padding-top: 96px;
  }

  &__content {
    display: grid;
    gap: 64px;

    @include media(landscape) {
      gap: 100px;
    }

    @include media(desktopAll) {
      gap: 200px;
    }
  }
}

.title {
  @include media(portrait) {
    font-size: 28px;
    line-height: 36px;
  }

  @include media(landscape) {
    font-size: 28px;
    line-height: 36px;
  }


  @include media(desktopAll) {
    font-size: 32px;
    line-height: 40px;
  }
}

.filters {
  gap: 25px;
  margin-bottom: 32px;

  @include media(mobile) {
    margin-bottom: 48px;
  }
}

.filter {
  @include setProperty(color, var(--secondary-200), var(--secondary-500));
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;

  @include media(desktopHuge) {
    font-size: 20px;
  }

  &:hover {
    @include setProperty(color, var(--secondary-100), var(--secondary-300));
  }

  &.active {
    @include setProperty(color, var(--primary-500), var(--primary-900));
    opacity: 1;
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px 64px;

  @include media(desktopHuge) {
    gap: 100px 120px;
  }

  @include media(desktopBig) {
    gap: 100px 120px;
  }

  @include media(landscape) {
    gap: 100px 32px;
  }

  @include media(portrait) {
    gap: 100px 32px;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
    gap: 48px;
  }

  &.alternative-view {
    gap: 72px;

    @include media(landscape) {
      gap: 64px;

      display: block;
      column-count: 2;

      & > div {
        min-height: fit-content;

        &:first-child {
          margin-top: 80px;
        }

        &:not(:last-child) {
          margin-bottom: 80px;
        }
      }
    }

    @include media(desktopAll) {
      gap: 64px;

      display: block;
      column-count: 2;

      & > div {
        min-height: fit-content;

        &:first-child {
          margin-top: 80px;
        }

        &:not(:last-child) {
          margin-bottom: 80px;
        }
      }
    }

    @include media(portrait) {
      grid-template-columns: 1fr;
    }
  }
}

.viewAllButton {
  @include setProperty(color, var(--primary-500), var(--secondary-500));
  margin-left: auto;
  margin-top: var(--margin-lg);
}